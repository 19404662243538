import dynamic, { DynamicOptionsLoadingProps } from "next/dynamic";
import { openModal as openUrlModal } from "react-url-modal";
import { Loading } from "../Loading/Loading";
import type { BulkPremiumCalcModalProps } from "../BulkPremiumCalcModal/BulkPremiumCalcModal";
import type { CancelPolicyModalProps } from "../CancelPolicyModal/CancelPolicyModal";
import type { CreateClaimModalProps } from "../CreateClaimModal/CreateClaimModal";
import type { CreatePasswordModalProps } from "../CreatePasswordModal/CreatePasswordModal";
import type { DemoModalProps } from "../DemoModal/DemoModal";
import type { LoginModalProps } from "../LoginModal/LoginModal";
import type { PremiumCalcModalProps } from "../PremiumCalcModal/PremiumCalcModal";
import type { UpdatePaymentIntervalModalProps } from "../UpdatePaymentIntervalModal/UpdatePaymentIntervalModal";
import type { UpdatePolicyModalProps } from "../UpdatePolicyModal/UpdatePolicyModal";
import type { UserDataModalProps } from "../UserDataModal/UserDataModal";
import type { ViewClaimModalProps } from "../ViewClaimModal/ViewClaimModal";
import type { WithdrawPolicyModalProps } from "../WithdrawPolicyModal/WithdrawPolicyModal";

const dynamicOptions = {
	ssr: false,
	loading: (loadingProps: DynamicOptionsLoadingProps) => <Loading text=" " />,
};

// DYNAMICALLY IMPORT ROUTES
const BulkPremiumCalcModal = dynamic<BulkPremiumCalcModalProps>(
	async () =>
		import("../BulkPremiumCalcModal/BulkPremiumCalcModal").then(
			(m) => m.BulkPremiumCalcModal,
		),
	{ ssr: false },
);

const DemoModal = dynamic<DemoModalProps>(
	async () => import("../DemoModal/DemoModal").then((m) => m.DemoModal),
	{ ...dynamicOptions },
);

const CreatePasswordModal = dynamic<CreatePasswordModalProps>(
	async () =>
		import("../CreatePasswordModal/CreatePasswordModal").then(
			(m) => m.CreatePasswordModal,
		),
	{ ...dynamicOptions },
);

const UserDataModal = dynamic<UserDataModalProps>(
	async () =>
		import("../UserDataModal/UserDataModal").then((m) => m.UserDataModal),
	{ ...dynamicOptions },
);

const UpdatePaymentIntervalModal = dynamic<UpdatePaymentIntervalModalProps>(
	async () =>
		import("../UpdatePaymentIntervalModal/UpdatePaymentIntervalModal").then(
			(m) => m.UpdatePaymentIntervalModal,
		),
	{ ...dynamicOptions },
);

const UpdatePolicyModal = dynamic<UpdatePolicyModalProps>(
	async () =>
		import("../UpdatePolicyModal/UpdatePolicyModal").then(
			(m) => m.UpdatePolicyModal,
		),
	{ ...dynamicOptions },
);

const PremiumCalcModal = dynamic<PremiumCalcModalProps>(
	async () =>
		import("../PremiumCalcModal/PremiumCalcModal").then(
			(m) => m.PremiumCalcModal,
		),
	{ ...dynamicOptions },
);
const WithdrawPolicyModal = dynamic<WithdrawPolicyModalProps>(
	async () =>
		import("../WithdrawPolicyModal/WithdrawPolicyModal").then(
			(m) => m.WithdrawPolicyModal,
		),
	{ ...dynamicOptions },
);

const CancelPolicyModal = dynamic<CancelPolicyModalProps>(
	async () =>
		import("../CancelPolicyModal/CancelPolicyModal").then(
			(m) => m.CancelPolicyModal,
		),
	{ ...dynamicOptions },
);

const ForgotPasswordModal = dynamic(
	async () =>
		import("../ForgotPasswordModal/ForgotPasswordModal").then(
			(m) => m.ForgotPasswordModal,
		),
	{ ...dynamicOptions },
);

const LoginModal = dynamic<LoginModalProps>(
	async () => import("../LoginModal/LoginModal").then((m) => m.LoginModal),
	{ ...dynamicOptions },
);

const CreateClaimModal = dynamic<CreateClaimModalProps>(
	async () =>
		import("../CreateClaimModal/CreateClaimModal").then(
			(m) => m.CreateClaimModal,
		),
	{ ...dynamicOptions },
);

const ViewClaimModal = dynamic<ViewClaimModalProps>(
	async () =>
		import("../ViewClaimModal/ViewClaimModal").then(
			(m) => m.ViewClaimModal,
		),
	{ ...dynamicOptions },
);

export const modalRoutes = {
	BulkPremiumCalc: BulkPremiumCalcModal,
	Demo: DemoModal,
	CreatePassword: CreatePasswordModal,
	UpdatePaymentInterval: UpdatePaymentIntervalModal,
	UpdatePolicy: UpdatePolicyModal,
	UserData: UserDataModal,
	PremiumCalc: PremiumCalcModal,
	WithdrawPolicy: WithdrawPolicyModal,
	CancelPolicy: CancelPolicyModal,
	ForgotPassword: ForgotPasswordModal,
	Login: LoginModal,
	CreateClaim: CreateClaimModal,
	ViewClaim: ViewClaimModal,
} as const;

export type ModalRoutes = typeof modalRoutes;
export type WithModalParams<T = Record<string, any>> = {
	params: T;
};

export type ModalRouteProps<T extends ModalRoutes = ModalRoutes> = {
	[Key in keyof ModalRoutes]: React.ComponentPropsWithRef<
		T[Key]
	> extends WithModalParams
		? React.ComponentPropsWithRef<T[Key]>["params"]
		: never;
};

export const openModal = async <
	ModalName extends keyof ModalRoutes = keyof ModalRoutes,
>(
	name: ModalName,
	params?: ModalRouteProps[ModalName],
) => {
	return openUrlModal({ name, params });
};

export const portalRouteNames: Array<keyof typeof modalRoutes> = [
	"UpdatePaymentInterval",
	"UpdatePolicy",
	"UserData",
	"WithdrawPolicy",
	"CancelPolicy",
	"CreateClaim",
	"ViewClaim",
];

// 🔬 TBD: Please evaluate
